import logo from './images/main.png';
import './App.css';
import Pages from './pages';

function App() {
  return (
    <div>
      <Pages/>
    </div>
  );
}

export default App;
