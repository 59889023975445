import React from 'react';
import logo from '../images/main.png'
import Navbar from './components/Navbar';
import Footer from './components/Footer';

export default function About() {
    return (
        <>
            <Navbar selected="about"/>
            <div className=''>
                <section className="bg-slate-100 dark:text-white dark:bg-slate-900">
                    <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12">
                        <div class="mr-auto place-self-center lg:col-span-7">
                            <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">Introduction</h1>
                            <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Al-Haseeb Traders was established in Lahore, Pakistan in 2002. It started operating as a retail entity whereby its initial activities included trading in building and construction materials in the Local Market. Despite significant challenges and competition in the operating marketplace, the Company underpinned significant growth in its sales, profits, and margins and achieved record levels.</p>
                            
                        </div>
                        <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
                            <img src={logo} alt="mockup"/>
                        </div>                
                    </div>
                </section>
                <section class="bg-slate-100 dark:text-white dark:bg-slate-900">
                    <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                        <div class="mx-auto max-w-screen-sm text-center">
                            <h2 class="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">Mission</h2>
                            <p class="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">To maintain and further enhance the leading position as a quality manufacturer and supplier of products and services in Lahore and the Asian region. Our aim is to deliver operational excellence and customer satisfaction at all times. We strive to bring efficient processes and innovative solutions to our clients through the introduction of new products and technologies.</p>
                        </div>
                    </div>
                </section>
                <section class="bg-slate-100 dark:text-white dark:bg-slate-900">
                    <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                        <div class="mx-auto max-w-screen-sm text-center">
                            <h2 class="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">Vision</h2>
                            <p class="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">To grow into the position of the market leader in the Asian region as a manufacturer and supplier of products and services, ensuring the highest level of quality and excellence.</p>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}