import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Home from './Home.js';
import About from './About';
import Contact from './Contact.js';

const Pages = () => {
    return(
        <Router>
            <Routes>
                <Route exact path="/" element= {<Home/>} />
                <Route path = "/about" element = {<About/>} />
                <Route path = "/contact" element={<Contact/>} />
                {/* <Route path = "/terms" element = {<Terms/>} />
                <Route path = "/privacy" element = {<Privacy/>} />
                <Route path='/pricing' element={<Pricing/>} /> */}
            </Routes>
        </Router>
    );
};

export default Pages;